import React, { useState, useEffect, useRef } from "react"
import Slider from "react-slick"
import { useMediaQuery } from "react-responsive"

import { Wrapper, Card } from "./style"

const SliderAsNav = ({ node }) => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 991px)",
  })
  const isDesktop = useMediaQuery({
    query: "(min-device-width: 992px)",
  })

  const [state, setState] = useState({ nav1: null, nav2: null })
  const slider1 = useRef()
  const slider2 = useRef()

  useEffect(() => {
    setState({
      nav1: slider1.current,
      nav2: slider2.current,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { nav1, nav2 } = state

  return (
    <Wrapper>
      <div
        data-aos="fade-up"
        data-aos-duration="800"
        data-aos-delay="600"
        data-aos-once={true}
      >
        <Slider
          asNavFor={nav2}
          ref={slider => (slider1.current = slider)}
          arrows={!isDesktop && true}
        >
          {node &&
            node.map((source_url, i) => (
              <Card sliderTop={true} key={i} bg={source_url}>
                <div></div>
              </Card>
            ))}
        </Slider>

        <Slider
          asNavFor={nav1}
          ref={slider => (slider2.current = slider)}
          swipeToSlide={true}
          focusOnSelect={true}
          slidesToShow={4}
          arrows={isTabletOrMobileDevice ? false : true}
        >
          {node &&
            node.map((source_url, y) => (
              <Card sliderTop={false} key={y} bg={source_url}>
                <div></div>
              </Card>
            ))}
        </Slider>
      </div>
    </Wrapper>
  )
}

export default SliderAsNav
