import React, { Fragment } from "react"
import {
  Wrapper,
  HeroImg,
  HeroInfo,
  Title,
  TextContainer,
  Subtitle,
  SmallText,
  EventSupTitle,
  EventContainer,
  NoFullRes,
} from "./style"

import Blob from "../../common/Blob/Blob"
import { useMediaQuery } from "react-responsive"
import CalendarIcon from "../../../images/lyme-local-images/Calendar-3.png"
import PinIcon from "../../../images/lyme-local-images/Pin-2.png"

const PastEventsHero = ({ node }) => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 991px)",
  })

  const isNotebookOrIpad = useMediaQuery({
    query: "(max-device-width: 1199px)",
  })

  const isFullHd = useMediaQuery({
    query: "(min-device-width: 1442px)",
  })

  return (
    <Wrapper>
      <Fragment>
        <Blob
          toTop={
            isTabletOrMobileDevice ? "17%" : isNotebookOrIpad ? "-10%" : "25%"
          }
          toRight={
            isTabletOrMobileDevice ? "13%" : isNotebookOrIpad ? "15%" : "15%"
          }
          customWidth={
            isTabletOrMobileDevice ? "0" : isNotebookOrIpad ? "15vw" : "37.5vw"
          }
          customHeight={isNotebookOrIpad ? "50vw" : "40.1vw"}
          delay={"400"}
        />

  {/*       {!isFullHd ? (
          <Blob
            toTop={
              isTabletOrMobileDevice ? "17%" : isNotebookOrIpad ? "-10%" : "70%"
            }
            toRight={
              isTabletOrMobileDevice ? "13%" : isNotebookOrIpad ? "15%" : "65%"
            }
            customWidth={
              isTabletOrMobileDevice ? "0" : isNotebookOrIpad ? "0" : "10vw"
            }
            customHeight={isNotebookOrIpad ? "50vw" : "40.1vw"}
            delay={"400"}
          />
        ) : (
          <Blob
            toTop={
              isTabletOrMobileDevice ? "17%" : isNotebookOrIpad ? "-10%" : "70%"
            }
            toRight={
              isTabletOrMobileDevice ? "13%" : isNotebookOrIpad ? "15%" : "65%"
            }
            customWidth={"0"}
            customHeight={isNotebookOrIpad ? "50vw" : "40.1vw"}
            delay={"400"}
          />
        )} */}

        <Blob
          toTop={
            isTabletOrMobileDevice ? "17%" : isNotebookOrIpad ? "-10%" : "80%"
          }
          toLeft={
            isTabletOrMobileDevice ? "13%" : isNotebookOrIpad ? "-2%" : "-2%"
          }
          customWidth={
            isTabletOrMobileDevice ? "0" : isNotebookOrIpad ? "0" : "6vw"
          }
          customHeight={isNotebookOrIpad ? "50vw" : "40.1vw"}
          delay={"400"}
        />

        <HeroImg
          bg={node.hero_image && node.hero_image.source_url}
          data-aos-once={true}
          data-aos="fade-up"
          data-aos-duration="800"
          data-aos-delay="200"
        />

        <HeroInfo>
          <TextContainer>
            <Title
              data-aos-once={true}
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="400"
              dangerouslySetInnerHTML={{
                __html: node.event_title,
              }}
            />
            <Subtitle
              data-aos-once={true}
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="600"
              dangerouslySetInnerHTML={{
                __html: node.Event_Tagline,
              }}
            />

            {/*  <SmallText
              data-aos-once={true}
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="800"
            >
              {node.acf.past_event_page_hero_text}
            </SmallText> */}
          </TextContainer>
          <EventContainer
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="400"
          >
            <EventSupTitle>PAST EVENT DETAILS</EventSupTitle>

            <ul>
              <li
                data-aos-once={true}
                data-aos="fade"
                data-aos-duration="800"
                data-aos-delay="200"
              >
                <img src={CalendarIcon} alt="date" />
                <span>{node.date}</span>
              </li>
              <li
                data-aos-once={true}
                data-aos="fade"
                data-aos-duration="800"
                data-aos-delay="400"
              >
                <img src={PinIcon} alt="place" />
                <span>{node.location}</span>
              </li>
            </ul>
          </EventContainer>
        </HeroInfo>
      </Fragment>
    </Wrapper>
  )
}

export default PastEventsHero
