import styled from "styled-components"
import { LG } from "../../../../utils/constants"
import ArrowLeft from "../../../../images/arrow-left.png"
import ArrowRight from "../../../../images/arrow-right.png"
import ArrowMobileLeft from "../../../../images/arrow-mobile-left.svg"
import ArrowMobileRight from "../../../../images/arrow-mobile-right.svg"

export const Wrapper = styled.div`
  margin-top: 40px;

  > div {
    max-width: 1256px;
  }

  @media (max-width: ${LG}) {
    margin-bottom: 20px;
  }

  .slick-slider {
    height: 166px;
    &:first-of-type {
      height: 665px;
      margin-bottom: 20px;

      > div {
        margin: 0px;
      }

      @media (max-width: ${LG}) {
        height: 200px;
        margin-bottom: 10px;

        .slick-slide {
          padding: 0;
        }
      }
    }

    &:last-of-type {
      @media (max-width: ${LG}) {
        height: 47px;
        .slick-slide {
          padding: 0 5px;
        }
      }
    }
  }

  .slick-slide {
    cursor: pointer;
    padding: 0 10px;
    box-sizing: border-box;
    margin: 0;
  }

  .slick-list {
    margin: 0 -10px;
    &:first-of-type {
      margin: 0;
    }
  }

  .slick-next,
  .slick-prev {
    transform: translate(-50%, -50%);
  }

  .slick-active + .slick-active {
    opacity: 0.6;
  }

  .slick-active {
    opacity: 1;
  }

  .slick-next {
    width: 64px;
    height: 64px;
    z-index: 999;
    right: -45px;
    top: 48%;
  }
  .slick-prev {
    width: 64px;
    height: 64px;
    z-index: 999;
    left: -4px;
    display: block;
    opacity: 1;
    top: 48%;
  }

  .slick-prev:before {
    content: "";
    width: 84px;
    height: 84px;
    background: url(${ArrowLeft}) no-repeat center center / contain;
    display: block;
    opacity: 1;
  }

  .slick-next:before {
    content: "";
    width: 84px;
    height: 84px;
    background: url(${ArrowRight}) no-repeat center center / contain;
    display: block;
    opacity: 1;
  }

  @media (max-width: ${LG}) {
    .slick-next {
      width: auto;
      height: auto;
      right: 0;
      top: 50%;
      z-index: 6;
    }
    .slick-prev {
      width: auto;
      height: auto;
      left: 22px;
      top: 50%;
      z-index: 6;
    }
    .slick-prev:before {
      content: "";
      width: 24px;
      height: 24px;
      background: url(${ArrowMobileLeft}) no-repeat center center / contain;
      display: block;
      opacity: 1;
    }

    .slick-next:before {
      content: "";
      width: 24px;
      height: 24px;
      background: url(${ArrowMobileRight}) no-repeat center center / contain;
      display: block;
      opacity: 1;
    }
  }

  @media (max-width: ${LG}) {
    .slick-dots {
      bottom: 20px;
      li {
        background-color: red;
        border-radius: 50%;
        width: 4px;
        height: 4px;
        vertical-align: middle;
        transition: ${props => props.theme.defaultTransition()};
        background-color: #b0c9cb;

        &.slick-active {
          width: 12px;
          height: 12px;
          background: linear-gradient(97.08deg, #f38b97 20.01%, #f4904d 75.82%);
        }

        button {
          width: 12px;
          height: 12px;
          &:before {
            display: none;
          }
        }
      }
    }
  }
`

export const Card = styled.div`
  background-color: white;
  background: url(${props => props.bg}) no-repeat center center / cover;
  height: ${props => (props.sliderTop ? "665px" : "166px")};
  position: relative;
  display: flex;

  transition: ${props => props.theme.defaultTransition()};

  @media (max-width: ${LG}) {
    height: ${props => (props.sliderTop ? "200px" : "47px")};
  }
`
