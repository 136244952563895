import styled from "styled-components"

import { LG, XL, BREAKPOINT } from "../../../utils/constants"

import { MainH2, SupTitle } from "../../../utils/GlobalComponents"

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 572px 3fr;
  align-items: flex-start;
  justify-items: center;
  grid-template-rows: 780px;

  @media (max-width: ${XL}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${LG}) {
    grid-template-columns: 1fr;
    padding-bottom: 0;
    background-color: white;
    grid-template-rows: auto;
  }
`

export const HeroImg = styled.div`
  width: 100%;
  height: 100%;
  background: url(${props => props.bg}) no-repeat center center / cover;
  @media (max-width: ${XL}) {
    align-self: flex-start;
    justify-self: baseline;
    width: 80%;
    z-index: 2;
    position: relative;
  }

  @media (max-width: ${LG}) {
    height: 450px;
    width: 100%;
    margin-top: 65px;
  }
`

export const HeroInfo = styled.div`
  margin-top: 130px;
  padding-left: 30px;
  max-width: 576px;
  padding-bottom: 45px;
  position: relative;
  right: 15px;
  @media (max-width: ${XL}) {
    right: 45px;
    margin-top: 50px;
  }

  @media (max-width: ${LG}) {
    background-color: white;
    padding-bottom: 0;
    right: 0;
    max-width: unset;

    img {
      width: 100%;
    }
  }
`

export const TextContainer = styled.div`
  margin-bottom: 140px;
  position: relative;
  z-index: 4;

  @media (max-width: ${XL}) {
    margin-bottom: 45px;
  }

  @media (max-width: ${LG}) {
    padding-right: 30px;
  }
`

export const Title = styled(MainH2)`
  margin-bottom: 100px;
  font-weight: 300;
  max-width: unset;
  line-height: 48px;
  margin-bottom: 30px;

  strong {
    display: block;
    font-weight: 500;
  }

  &::after {
    content: "";
    display: block;
    width: 38px;
    height: 4px;
    background: ${props => props.theme.orangeGradient};
    position: relative;
    bottom: 5px;
    @media (max-width: ${BREAKPOINT}) {
      margin-top: 0;
    }
  }

  @media (max-width: ${XL}) {
    margin-bottom: 30px;
  }

  @media (max-width: ${LG}) {
    padding-left: 0;
    padding-top: 0;
    max-width: unset;
    padding-right: 30px;
  }
`

export const Subtitle = styled.h4`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 31px;
  color: ${props => props.theme.navy};
  margin-bottom: 10px;

  @media (min-width: ${LG}) {
    max-width: 455px;
  }

  @media (max-width: ${LG}) {
    font-weight: 500;
    font-size: 22px;
    line-height: 31px;
  }
`

export const SmallText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: ${props => props.theme.navy};
  letter-spacing: 0.01em;

  @media (min-width: ${LG}) {
    max-width: 454px;
  }

  @media (max-width: ${LG}) {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
  }
`

export const EventSupTitle = styled(SupTitle)`
  &:after {
    display: none;
  }
`

export const EventContainer = styled.div`
  ul {
    margin-top: 13px;
    padding: 40px 0px 40px 40px;
    background-color: ${props => props.theme.neutralSilver};
    display: grid;
    grid-template-columns: 1fr 0.9fr;
    border-radius: 8px;
    grid-gap: 10px;
    li {
      display: flex;
      align-items: center;
      margin-right: 10px;

      span {
        font-weight: 500;
        color: ${props => props.theme.neutralDark};
        font-size: 16px;
        line-height: 24px;
      }

      img {
        max-width: 20px;
        margin-right: 10px;
      }
    }
  }

  @media (max-width: ${XL}) {
    ul {
      grid-template-columns: 1fr;
      padding: 30px 0px 30px 40px;
    }
  }

  @media (max-width: ${LG}) {
    background-color: ${props => props.theme.neutralSilver};

    > span {
      padding-left: 30px;
      padding-top: 20px;
      display: block;
    }
    ul {
      padding: 10px 0 30px 30px;
      grid-gap: 5px;

      li {
        margin-bottom: 10px;
      }
    }
    margin: 30px 30px 30px 0;
    border-radius: 8px;
  }
`

