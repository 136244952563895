import styled from "styled-components"

import { LG, XL } from "../../../utils/constants"

import ArrowLeft from "../../../images/arrow-left.png"
import ArrowRight from "../../../images/arrow-right.png"

export const Wrapper = styled.div`
padding-bottom:120px;
  .slick-slider {
    /* padding: 20px 0 100px; */
   
  }
  .slick-slide {
    cursor: pointer;
    opacity: 1;
  }

  .slick-next,
  .slick-prev {
    transform: translate(-50%, -50%);
  }

  .slick-next {
    width: 64px;
    height: 64px;
    z-index: 999;
    right: 2px;

    @media (max-width: ${XL}) {
      right: 53px;
    }
  }

  .slick-active
    + .slick-active
    + .slick-active
    + .slick-active
    + .slick-active {
    opacity: 0.6;
  }

  .slick-active {
    opacity: 1;
  }

  .slick-prev {
    width: 64px;
    height: 64px;
    z-index: 999;
    left: 48px;
    display: block;
    opacity: 1;

    @media (max-width: ${XL}) {
      left: 98px;
    }
  }

  .slick-prev:before {
    content: "";
    width: 84px;
    height: 84px;
    background: url(${ArrowLeft}) no-repeat center center / contain;
    display: block;
    opacity: 1;
  }

  .slick-next:before {
    content: "";
    width: 84px;
    height: 84px;
    background: url(${ArrowRight}) no-repeat center center / contain;
    display: block;
    opacity: 1;
  }

  .slick-dots {
    bottom: -50px;

    @media (max-width: ${LG}) {
      bottom: -42px;
    }
    li {
      background-color: red;
      border-radius: 50%;
      width: 4px;
      height: 4px;
      vertical-align: middle;
      transition: ${props => props.theme.defaultTransition()};
      background-color: white;
      opacity: 0.8;

      &.slick-active {
        width: 12px;
        height: 12px;
        background: white;
        opacity: 1;
      }

      button {
        width: 12px;
        height: 12px;
        &:before {
          display: none;
        }
      }
    }
  }
`

export const Grid = styled.div``

export const Card = styled.div`
  background: #ffffff;
  box-shadow: 1px 28px 60px rgba(0, 43, 52, 0.08);
  border-radius: 8px;
  padding: 10px 10px 30px 10px;

  max-width: 984px;
  height: 487px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 0.6fr 1fr;
  align-items: center;

  img {
    max-width: 180px;
    justify-self: end;
    margin-right: 40px;
    position: relative;
    bottom: 50px;
    width:180px;
    border-radius:50%;
  }
  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    color: ${props => props.theme.navy};
    margin-bottom: 5px;
  }

  h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    background: ${props => props.theme.orangeGradient};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 35px;

    &:after {
      content: "";
      display: block;
      width: 38px;
      height: 4px;
      background: ${props => props.theme.orangeGradient};
      margin-top: 14px;
    }

    @media (max-width: ${LG}) {
      margin-bottom: 40px;
      &:after {
        margin-top: 12px;
      }
    }
  }

  p {
    margin: 0;
    font-weight: 300;
    font-size: 24px;
    line-height: 31px;
    color: ${props => props.theme.navy};
  }

  @media (max-width: ${XL}) {
    max-width: 800px;
  }

  @media (max-width: ${LG}) {
    width: 80%;
    grid-template-columns: 1fr;
    height: auto;
    box-shadow: 1px 28px 60px rgba(0, 43, 52, 0.03);

    img {
      margin: auto;
      position: initial;
      justify-self: center;
      max-width: 150px;
      padding: 20px 0 10px;
      max-width:150px;
    }

    h3 {
      font-size: 22px;
      line-height: 31px;
    }

    h4 {
      &:after {
        position: absolute;
        left: 50%;
        margin-left: -16px;
      }
    }

    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.01em;
      margin-top: 50px;
    }
  }
`

export const TextContainer = styled.div`
  padding-left: 30px;
  @media (min-width: ${LG}) {
    max-width: 470px;
  }

  @media (max-width: ${LG}) {
    text-align: center;
    position: relative;
    padding: 0 15px;
  }
`
