import React, { Fragment } from "react"
import { DefaultWrapper } from "../../../utils/GlobalComponents"
import {
  Wrapper,
  Text,
  MainBtn,
  Title,
  SmallText,
  MainContainer,
} from "./style"

import SliderPresentations from "../../ConferencePresentations/SliderPresentations/SliderPresentations"

const PastPresentations = ({ node, pastEventYear }) => {
  return (
    <Wrapper>
      <Fragment>
        <DefaultWrapper>
          <MainContainer>
            <Title
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="400"
              data-aos-once={true}
              maxWidth={"unset"}
            >
              {node.section_title}
            </Title>
            <Text
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="600"
              data-aos-once={true}
              dangerouslySetInnerHTML={{ __html: node.section_description }}
            />

            {node.show_event_recording_button && (
              <MainBtn
                primary={"A"}
                to={node.event_recording_link}
                as="a"
                target="_blank"
                href={node.event_recording_link}
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="700"
                data-aos-once={true}
              >
                <span> Watch live event recording</span>
              </MainBtn>
            )}

            <SmallText
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="800"
              data-aos-once={true}
            >
              Enjoy some of the highlights from the{" "}
              {node.conference_videos.post_title} LymeMIND conference.
            </SmallText>
          </MainContainer>
        </DefaultWrapper>
        <SliderPresentations
          node={node}
          pastEventYear={pastEventYear}
          pastPresentationPage={true}
        />
      </Fragment>
    </Wrapper>
  )
}

export default PastPresentations
