import React, { Fragment } from "react"
import { Wrapper } from "./style"
import { DefaultWrapper } from "../../utils/GlobalComponents"
import SliderSpeakers from "./SliderSpeakers/SliderSpeakers"

const Speakers = ({ node, eventSpeakers }) => {
  

  return (
    <Fragment>
      <Wrapper>
        <DefaultWrapper>
          <h5
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="200"
            data-aos-once={true}
          >
            {node.title + " lymemind conference speakers"}
          </h5>
          <SliderSpeakers eventSpeakers={eventSpeakers} />
        </DefaultWrapper>
      </Wrapper>
    </Fragment>
  )
}

export default Speakers
