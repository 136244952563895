import styled from "styled-components"

import { SupTitle, MainH3, PrimaryBtn } from "../../../utils/GlobalComponents"
import { LG, XL } from "../../../utils/constants"

export const Wrapper = styled.div`
  background-color: ${props => props.theme.neutralSilver};
  > div {
    text-align: center;
    padding: 180px 0;
  }

  @media (min-width: ${LG}) {
    > div {
      max-width: 964px;
      margin: 0 auto;
    }
  }

  @media (max-width: ${XL}) {
    > div {
      padding: 60px 0;
    }
  }

  @media (max-width: ${LG}) {
    > div {
      padding: 60px 30px;
    }
  }
`

export const TopTitle = styled(SupTitle)``

export const Text = styled(MainH3)`
  margin: 20px 0 30px;

  @media (max-width: ${LG}) {
    font-size: 22px;
    line-height: 31px;
    font-weight: normal;
  }
`

export const MainBtn = styled(PrimaryBtn)`
  max-width: 185px;
  margin: 0 auto;
  line-height: 28px;
`
