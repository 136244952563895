import styled from "styled-components"

import { MainH3, PrimaryBtn, MainH2 } from "../../../utils/GlobalComponents"
import { LG } from "../../../utils/constants"

export const Wrapper = styled.div`
  text-align: center;
  padding: 100px 0 60px;

  @media (min-width: ${LG}) {
    margin: 0 auto;
  }

  @media (max-width: ${LG}) {
    padding: 60px 0 60px;
  }
`

export const Title = styled(MainH2)`
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;

  @media (max-width: ${LG}) {
    font-weight: 500;
    font-size: 32px;
    line-height: 36px;
  }
`
export const MainContainer = styled.div`
  padding: 0 30px;
  position:relative;
  z-index:10;
`

export const Text = styled(MainH3)`
  padding: 20px 0 45px;

  @media (min-width: ${LG}) {
    max-width: 936px;
    margin: 0 auto;
  }

  @media (max-width: ${LG}) {
    font-weight: normal;
    font-size: 22px;
    line-height: 31px;
  }
`

export const MainBtn = styled(PrimaryBtn)`
  max-width: 242px;
  margin: 0 auto;

  @media (max-width: ${LG}) {
    line-height: 2;
  }
`

export const SmallText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  margin-top: 80px;

  text-align: center;
  letter-spacing: 0.01em;
  color: ${props => props.theme.neutralDark};
  margin-bottom: -50px;

  @media (max-width: ${LG}) {
    margin-top: 50px;
    margin-bottom: 0;
  }
`
