import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import PastEventsHero from "../components/PastEventPage/Hero/Hero"
import PastAgenda from "../components/PastEventPage/PastAgenda/PastAgenda"
import Speakers from "../components/Speakers/Speakers"
import Artist from "../components/Artist/Artist"
import PastPresentations from "../components/PastEventPage/PastPresentations/PastPresentations"
import PastConferencePhotos from "../components/PastEventPage/PastConferencePhotos/PastConferencePhotos"

const PastEventsPage = ({ pageContext }) => {
  const { node } = pageContext

  return (
    <Layout>
      <SEO title="Past Events" />
      <PastEventsHero node={node.acf.event_information} />
      {/*  <PastAgenda node={node} /> */}

      {node.acf.speakers[0].speaker && (
        <Speakers node={node} eventSpeakers={node.acf.speakers[0].speaker} />
      )}

      <PastPresentations
        pastEventYear={
          node.acf.conference_presentations_section.conference_videos.post_title
        }
        node={node.acf.conference_presentations_section}
      />

    
      {node.acf.artists !== null && (
        <Artist node={node} eventArtist={node.acf.artists} />
      )}

      <PastConferencePhotos node={node.acf.conference_photos_section} />
    </Layout>
  )
}

export default PastEventsPage
