import styled from "styled-components"

import { XXL, LG } from "../../../utils/constants"

export const Wrapper = styled.div`
  background-color: ${props => props.theme.neutralSilver};
  margin-bottom: 50px;
  

  @media (max-width: ${LG}) {
    margin-bottom: 0;
  }

  > div {
    text-align: center;
    max-width: 1256px;
    margin: 0 auto;
    margin-top: -100px;
    padding-bottom: 20px;

    @media (max-width: ${XXL}) {
      width: 90%;
    }

    @media (max-width: ${LG}) {
      width: 100%;
    }
  }
`

export const SmallTitle = styled.h5`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${props => props.theme.neutralDark};
  padding-top: 30px;
  margin-bottom: -15px;
`
