import styled from "styled-components"
import { LG } from "../../utils/constants"

export const Wrapper = styled.div`
  background: linear-gradient(103.23deg, #f38b97 20.01%, #f4904d 75.82%);
  padding: 80px 0 20px;

  @media (max-width: ${LG}) {
    padding: 40px 0 80px;
    }

  h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: white;
    margin-bottom: 40px;

    @media (max-width: ${LG}) {
      padding: 0 80px;
      margin-bottom: 30px;
    }
  }
`
