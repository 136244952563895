import React from "react"
import { DefaultWrapper } from "../../../utils/GlobalComponents"
import { Wrapper, SmallTitle } from "./style"
import SliverAsNav from "./SliderAsNav/SliderAsNav"

const PastConferencePhotos = ({ node }) => {
  if (node.section_title === null || node.photos === null) return null
  return (
    <Wrapper>
      <div>
        <DefaultWrapper>
          <SmallTitle
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="400"
            data-aos-once={true}
          >
            {node.section_title}
          </SmallTitle>
        </DefaultWrapper>
        <SliverAsNav
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="600"
          data-aos-once={true}
          node={node.photos}
        />
      </div>
    </Wrapper>
  )
}

export default PastConferencePhotos
