import React from "react"
import { useStaticQuery } from "gatsby"
import Slider from "react-slick"
import { Wrapper, Card, TextContainer } from "./style"

const SliderSpeakers = ({ eventSpeakers }) => {
  const {
    allWordpressWpSpeakers: { edges },
  } = useStaticQuery(graphql`
    query SliderSpeakersQuery {
      allWordpressWpSpeakers {
        edges {
          node {
            title
            acf {
              speaker_image {
                alt_text
                source_url
              }
              speaker_name
              speaker_position
              speaker_text
            }
          }
        }
      }
    }
  `)

  const settings = {
    infinite: true,
    slidesToShow: 1,
    autoplay: false,
    speed: 800,
    dots: true,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 1,
          infinite: true,
          arrows: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  }

  const speakersArray = []
  const finalArray = []

  eventSpeakers.forEach(singleEventSpeaker => {
    speakersArray.push(singleEventSpeaker.post_title)
  })

  edges.forEach(item =>
    eventSpeakers.forEach(singleSpeak => {
      if (singleSpeak.post_title === item.node.title) {
        finalArray.push(item)
      }
    })
  )

  return (
    <Wrapper
      data-aos="fade-up"
      data-aos-duration="800"
      data-aos-delay="400"
      data-aos-once={true}
    >
      <Slider {...settings}>
        {finalArray.map(({ node }, i) => (
          <div key={i}>
            <Card>
              <img
                src={
                  node.acf.speaker_image
                    ? node.acf.speaker_image.source_url
                    : ""
                }
                alt={
                  node.acf.speaker_image ? node.acf.speaker_image.alt_text : ""
                }
              />
              <TextContainer>
                <h3>{node.acf.speaker_name}</h3>
                <h4>{node.acf.speaker_position}</h4>
                <p>{node.acf.speaker_text}</p>
              </TextContainer>
            </Card>
          </div>
        ))}
      </Slider>
    </Wrapper>
  )
}

export default SliderSpeakers
